import { Trans, t } from "@lingui/macro";
import { Stack, Grid, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import LandingLayout from "../../components/landings/LandingLayout";
import HeroLanding from "../../components/HeroLanding";
import SecondaryButton from "../../components/SecondaryButton";
import { theme } from "../../themeV2";
import ProductModal from "../../components/ProductModal";
import DefaultSection from "../../components/landings/DefaultSection";
import heroImg from "../../images/products/nextportfolio-4/hero.png";
import heroLogoImg from "../../images/products/nextportfolio-4/logo.png";
import aiLogoImg from "../../images/products/nextportfolio-4/ai-logo.png";
import bento2Img from "../../images/products/nextportfolio-4/bento-2.png";
import bento3Img from "../../images/products/nextportfolio-4/bento-3.png";
import bento4Img from "../../images/products/nextportfolio-4/bento-4.png";
import sec3Icon1 from "../../images/products/nextportfolio-4/sec-3_icon-1.png";
import sec3Icon2 from "../../images/products/nextportfolio-4/sec-3_icon-2.png";
import sec3Icon3 from "../../images/products/nextportfolio-4/sec-3_icon-3.png";
import sec3Icon4 from "../../images/products/nextportfolio-4/sec-3_icon-4.png";
import sec3Icon5 from "../../images/products/nextportfolio-4/sec-3_icon-5.png";
import sec3Icon6 from "../../images/products/nextportfolio-4/sec-3_icon-6.png";
import ImageComponent from "../../components/landings/ImageComponent";

const mainColor = theme.palette.colors.green.main;

const metadata = {
  title: "Nextportfolio4 – Portfolio Management Tool for Advisors and Wealth Managers | Allfunds",
  keywords: " Wealth Management Solutions, Portfolio Management Solutions, Artificial Intelligence, Portfolio Health, Client Onboarding, roboadvisor, execution only, discretionary model portfolios",
  description:
    "Nextportfolio Portfolio Management Solutions, Regulatory, Reporting, Dealing, Alerts, Artificial Intelligence, Portfolio Health, Client Onboarding, roboadvisor, execution only, discretionary model portfolios, API",
};

const SEC_2_DATA = [
  {
    col1: {
      color: theme.palette.colors.blueLight.main,
      title: t`Manual Work Overload`,
    },
    col2: {
      header: "The Challenge",
      text: t`Portfolio managers spend too much time on reporting, compliance, and admin tasks.`,
    },
    col3: {
      header: "Nextportfolio4",
      text: <Trans><b>Portfolio analysis</b> powered by AI.</Trans>,
    },
  },
  {
    col1: {
      color: theme.palette.colors.green.main,
      title: t`Scaling Without Complexity`,
    },
    col2: {
      header: "The Challenge",
      text: t`More client portfolios mean more monitoring, adjustments, and rebalancing, creating operational strain.`,
    },
    col3: {
      header: "Nextportfolio4",
      text: <Trans><b>Real-time monitoring</b>, portfolio rebalancing and bulk execution.</Trans>,
    },
  },
  {
    col1: {
      color: theme.palette.colors.purple.main,
      title: t`Real-Time Portfolio Visibility`,
    },
    col2: {
      header: "The Challenge",
      text: t`Constant monitoring is needed to ensure portfolios align with client goals and compliance.`,
    },
    col3: {
      header: "Nextportfolio4",
      text: <Trans>Portfolio Health provides a complete <b>portfolio status</b>.</Trans>,
    },
  },
  {
    col1: {
      color: theme.palette.colors.blue.dark,
      title: t`Rigid Reporting & Customization`,
    },
    col2: {
      header: "The Challenge",
      text: t`Standard reports don’t always fit client needs or regulatory requirements.`,
    },
    col3: {
      header: "Nextportfolio4",
      text: <Trans>Customizable <b>AI-generated reporting</b> and market insights.</Trans>,
    },
  },
];

const SEC_3_DATA = {
  items: [
    {
      icon: sec3Icon1,
      title: "Dashboard",
    },
    {
      icon: sec3Icon2,
      title: "Analytics",
    },
    {
      icon: sec3Icon3,
      title: "Reporting",
    },
    {
      icon: sec3Icon4,
      title: "Management",
    },
    {
      icon: sec3Icon5,
      title: "Regulatory",
    },
    {
      icon: sec3Icon6,
      title: "Dealing",
    },
  ],
  content: {
    title: t`One Solution that meets your teams' needs`,
    description: t`Equip your team with the best tools and streamline all processes efficiently`,
    items: [
      {
        title: t`CIOs & Portfolio Managers`,
        description: t`A flexible engine to efficiently build and manage model portfolios across different risk profiles and strategies.`,
      },
      {
        title: t`Advisors & Private Bankers`,
        description: t`Empower advisors to deliver and drive an exceptional service managing clients’ wealth holistically.`,
      },
      {
        title: t`Compliance & Risk`,
        description: t`Identify and monitor key insights of your clients while adhering to the regulatory landscape and ensuring compliance.`,
      },
      {
        title: t`Middle Officers`,
        description: t`Monitor and control operational burdens and risks, ensuring seamless execution and integration processes.`,
      },
    ],
  },
};

function BentoGrid() {
  return (
    <Box>
      <Grid container spacing={{ xs: 1, md: 2 }} color={theme.palette.colors.white.main} textAlign="left">
        <Grid item xs={12} md={5} height="324px">
          <Stack sx={{
            background: "linear-gradient(90deg, #C22C74 16.96%, #447CCB 78.46%)",
            borderRadius: "24px",
            p: 2,
            height: "100%",
            justifyContent: "space-between",
          }}>
            <Stack width="100%" alignItems="flex-end">
              <img src={aiLogoImg} alt="AI-Driven Portfolio Analysis" width="80" height="49" />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h3"><Trans>AI-Driven Portfolio Analysis</Trans></Typography>
              <Typography color={theme.palette.colors.white.main}><Trans>Portfolio analysis with Integrated Artificial
                Intelligence enhancing analytical and reporting capabilities.</Trans></Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={7} height="324px" display={{ xs: "none", sm: "block" }}>
          <Box height="100%">
            <Grid container backgroundColor="#061D3E" borderRadius="24px" overflow="hidden" height="100%">
              <Grid item xs={6}>
                <ImageComponent src={bento2Img} alt="Proactive Notifications" extraStyles={{ maxHeight: "300px" }} />
              </Grid>
              <Grid item xs={6}>
                <Stack
                  backgroundColor={theme.palette.colors.green.main}
                  p={2}
                  borderRadius="24px"
                  spacing={1}
                  height="100%"
                  justifyContent="flex-end">
                  <Typography variant="h3"><Trans>Proactive <br />Notifications</Trans></Typography>
                  <Typography color={theme.palette.colors.white.main}><Trans>An advanced alerts module that facilitates
                    controlled, streamlined management of investment proposals and captures key
                    insights.</Trans></Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} height="324px" display={{ sm: "none" }}>
          <Stack
            p={2}
            justifyContent="flex-end"
            borderRadius="24px"
            height="100%"
            sx={{ background: `linear-gradient(0deg, rgba(7,29,62,0.7) 45%, rgba(7,29,62,0.2) 67%), url(${bento2Img}) center / cover` }}>
            <Typography variant="h3" mb={1}><Trans>Proactive Notifications</Trans></Typography>
            <Typography color={theme.palette.colors.white.main}><Trans>An advanced alerts module that facilitates
              controlled, streamlined management of investment proposals and captures key insights.</Trans></Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} height="324px">
          <Stack
            p={2}
            justifyContent="flex-end"
            borderRadius="24px"
            height="100%"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${bento3Img})`,
            }}>
            <Typography variant="h3" mb={1}><Trans>Portfolio Health</Trans></Typography>
            <Typography color={theme.palette.colors.white.main}><Trans>A powerful Health Checker designed for advisors
              and bankers to effortlessly monitor their client's portfolio health.</Trans></Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} height="324px">
          <Stack
            p={2}
            justifyContent="flex-end"
            borderRadius="24px"
            height="100%"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${bento4Img})`,
            }}>
            <Typography variant="h3" mb={1}><Trans>Central Hub</Trans></Typography>
            <Typography color={theme.palette.colors.white.main}><Trans>The flexibility to configure the tool to match
              your unique business requirements, whether your require a hierarchical system or a business line
              structure.</Trans></Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

function Nextportfolio4() {
  const [subTitle, setSubTitle] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleOnclickInfo = (key) => {
    setSubTitle(key);
    setShowModal(true);
  };

  const renderRequestButton = (key) => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo(key)}
        text={<Trans>Request info</Trans>}
      />
    );
  };

  return (
    <LandingLayout
      seoTitle={metadata.title}
      seoKeyWords={metadata.keywords}
      seoDescription={metadata.description}
    >
      <HeroLanding
        title={t`Nextportfolio4 Transform the way you connect with clients`}
        description={t`Allfunds unveils Nextportfolio4, an innovative release designed to streamline portfolio management holistically.`}
        handleOnclickInfo={() => handleOnclickInfo("Nextportfolio4")}
        multimedia={heroImg}
        widthImage="100%"
        kindImg={heroLogoImg}
        grid={{
          columnLeft: { md: 5 },
          columnRight: { md: 7 },
        }}
      />
      <DefaultSection
        textAlign="center"
        pretitleAlign="center"
        pretitle="Nextportfolio4"
        pretitleColor={mainColor}
        title={t`Four New Features to Elevate Your Experience!`}
        buttons={renderRequestButton("Nextportfolio4")}
        description={<BentoGrid />}
      />
      <DefaultSection
        textAlign="center"
        title={t`What sets Nextportfolio4 apart?`}
        description={
          <>
            <Typography
              width={{ md: "70%" }}
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <Trans>
                Empower your financial advisors and portfolio managers with
                tools to streamline processes, personalize strategies, and drive
                commercial activity. Our solutions provide a 360° portfolio
                view, real-time rebalancing capabilities, and a scalable
                platform for growth.
              </Trans>
            </Typography>
            <Stack
              spacing={2}
              pt={2}
              style={{ marginBottom: "24px" }}
              position="relative"
            >
              <Box
                component="span"
                minHeight="100%"
                width={{ xs: "50%", md: "33.333333%" }}
                backgroundColor={theme.palette.colors.green.light}
                position="absolute"
                top="24px"
                right="12px"
                borderRadius="24px"
              ></Box>
              <Box textAlign="left" display={{ xs: "none", md: "block" }}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item xs={6} md={4}>
                    <Typography mb={-1} fontWeight="700">
                      {SEC_2_DATA[0].col2.header}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography mb={-1} fontWeight="700" color={mainColor}>
                      {SEC_2_DATA[0].col3.header}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {SEC_2_DATA?.map(({ col1, col2, col3 }, index) => (
                <Box
                  p={2}
                  backgroundColor={theme.palette.colors.grey.light}
                  borderRadius="24px"
                  textAlign="left"
                >
                  <Grid container spacing={2} alignItems={{ md: "center" }}>
                    <Grid item xs={12} md={4}>
                      <Typography fontSize="18px" fontWeight="700">
                        <Box component="span" color={col1.color}>
                          {"0" + (index + 1)}
                        </Box>{" "}
                        {col1.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Typography
                        mb={{ xs: 1, md: 2 }}
                        fontWeight="700"
                        display={{ md: "none" }}
                      >
                        {col2.header}
                      </Typography>
                      <Typography>{col2.text}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Typography
                        mb={{ xs: 1, md: 2 }}
                        fontWeight="700"
                        display={{ md: "none" }}
                        color={mainColor}
                      >
                        {col3.header}
                      </Typography>
                      <Typography>{col3.text}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Stack>
          </>
        }
      />
      <Stack
        component="span"
        backgroundColor={theme.palette.colors.white.dark}
        borderRadius="48px 48px 0 0"
      >
        <DefaultSection
          textAlign="center"
          pretitleAlign="center"
          pretitle="Nextportfolio4"
          title={t`What is Nexportfolio?`}
          pretitleColor={theme.palette.colors.green.main}
          description={
            <>
              <Typography
                width={{ md: "70%" }}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <Trans>
                  Build and manage client and model portfolios with intuitive
                  planning and reporting tools-designed specifically with
                  advisors and asset managers
                </Trans>
              </Typography>
              <Stack spacing={{ xs: 1, md: 2 }}>
                <Box>
                  <Grid container spacing={{ xs: 1, md: 2 }}>
                    {SEC_3_DATA?.items?.map(({ icon, title }) => (
                      <Grid item key={title} xs={6} sm={4} lg={2}>
                        <Stack
                          backgroundColor={theme.palette.colors.white.main}
                          borderRadius="24px"
                          spacing={1}
                          p={2}
                          alignItems="center"
                        >
                          <img src={icon} alt={title} width="60" height="60" />
                          <Typography fontWeight="600" fontSize="21px">
                            {title}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Stack
                  spacing={4}
                  backgroundColor={theme.palette.colors.white.main}
                  borderRadius="24px"
                  p={4}
                >
                  <Stack component="header" spacing={1}>
                    <Typography variant="h3">
                      {SEC_3_DATA.content.title}
                    </Typography>
                    <Typography>{SEC_3_DATA.content.description}</Typography>
                  </Stack>
                  <Box>
                    <Grid container spacing={4}>
                      {SEC_3_DATA?.content?.items.map((item) => (
                        <Grid
                          item
                          key={item.title}
                          xs={12}
                          sm={6}
                          md={3}
                          textAlign="left"
                        >
                          <Typography variant="h4" mb={1}>
                            {item.title}
                          </Typography>
                          <Typography>{item.description}</Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  <Stack alignItems="center">
                    {renderRequestButton("Nextportfolio4")}
                  </Stack>
                </Stack>
              </Stack>
            </>
          }
        />
      </Stack>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Nextportfolio4"
          product="nextportfolio-4"
          hasTemplate={false}
          subtitle={subTitle}
        />
      )}
    </LandingLayout>
  );
}

export default Nextportfolio4;
